import { ReactNode, useEffect } from 'react'
import LoadingScreen from 'components/LoadingScreen'
import useAuth from 'hooks/useAuth'

type AuthProviderProps = {
  children: ReactNode
}

// This is not an actual Context
// But might become in the future
export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const { isAuthLoading, handleGetRefreshToken } = useAuth()

  useEffect(() => {
    handleGetRefreshToken()
  }, [])

  if (isAuthLoading) {
    return <LoadingScreen />
  }

  return <>{children}</>
}
