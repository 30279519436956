import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router'

type GuardProps = {
  redirect: string
}

export const NotSaasUserGuard = ({ redirect }: GuardProps): JSX.Element => {
  // @ts-ignore
  const { isSaasUser } = useSelector((state) => state.dataUser)
  if (!isSaasUser) {
    return <Outlet />
  }
  return <Navigate to={redirect} />
}
