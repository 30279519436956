import { useGoCardlessDropin } from '@gocardless/react-dropin'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import onboardingApi from 'config/api/onboarding'
import configs from 'config/configs'
import { MERCHANT_ONBOARDING_ROUTES } from 'config/configs/routes'
import { useAnalytics } from 'hooks/useAnalytics'
import {
  updateDocusignSigningStatus,
  updateGocardlessStatus
} from 'redux/actions/Onboarding'
import { getErrorMessage } from 'utils/getErrorMessage'

const useDirectDebitSectionHook = () => {
  const [environment, setEnvironment] = useState('sandbox')
  const [billingFlowId, setBillingFlowId] = useState(null)
  const [signingPending, setSigningPending] = useState(false)
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const analytics = useAnalytics()

  const textButton = 'Submit'
  const user = useSelector((state) => state.dataUser)
  const { TRANSFER_FUNDS } = MERCHANT_ONBOARDING_ROUTES
  const { isGocardlessComplete } = useSelector((state) => state.dataOnboarding)
  const { isSaaSUser } = useSelector((state) => state.persistedDataRegister)
  const areDocumentsSigned = useSelector(
    (state) => state.dataOnboarding.areDocumentsSigned
  )

  const config = {
    billingRequestFlowID: billingFlowId,
    environment: environment, // either live or sandbox
    onSuccess: () => {
      onboardingApi.setGoCardlessStatus(true)
      dispatch(updateGocardlessStatus(true))
    },
    onExit: () => {
      onboardingApi.setGoCardlessStatus(false)
      dispatch(updateGocardlessStatus(false))
    }
  }

  const { open, ready } = useGoCardlessDropin(config)

  const getGocardlessToken = useQuery(
    'goCardless',
    onboardingApi.getGocardlessToken,
    {
      enabled: false,
      retry: false,
      onSuccess: (res) => {
        setBillingFlowId(res.billingFlowId)
        setEnvironment(res.environment)
      },
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  useQuery('getSelectedBankAccount', onboardingApi.getSelectedBankAccount, {
    retry: false,
    onSuccess: (res) => {
      setSelectedBankAccountId(res.id)
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  useQuery('getGoCardlessStatus', onboardingApi.getGoCardlessStatus, {
    retry: false,
    onSuccess: (res) => {
      if (res.status === false) {
        getGocardlessToken.refetch()
      }
      dispatch(updateGocardlessStatus(res.status))
    }
  })

  useQuery('signedDocuments', onboardingApi.getSignedDocuments, {
    retry: false,
    onSuccess: (res) => {
      dispatch(updateDocusignSigningStatus(res.documentsSigned))
      if (res.documentsSigned) {
        setSigningPending(false)
      }
    }
  })

  const openWindowWithLink = (url) => {
    window.open(
      url,
      '',
      'location=yes,height=720,width=1280,scrollbars=yes,status=yes'
    )
  }

  const initiateDocumentsSigning = useMutation(
    onboardingApi.getDocumentsSigningUrl,
    {
      retry: false,
      onSuccess: (res) => {
        openWindowWithLink(res.url)
        setSigningPending(true)
      },
      onError: (err) => {
        toast.error(getErrorMessage(err))
      }
    }
  )

  const finalizeOnboarding = useMutation(onboardingApi.finalizeOnboarding, {
    retry: false,
    onSuccess: () => {
      analytics.track(user.id, 'Application Submission')
      navigate(TRANSFER_FUNDS)
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  const actions = [
    {
      title: 'Direct Debit Mandate',
      action: () => {
        if (ready) open()
      },
      icon: configs.icons.gocardless,
      isDone: isGocardlessComplete,
      disabled: selectedBankAccountId === null,
      iconStyle: { width: '70px', height: '20px' },
      isLoading: false
    },
    {
      title: 'Your Bloom Contract',
      action: () => initiateDocumentsSigning.mutate(isSaaSUser),
      icon: configs.icons.docusign,
      iconStyle: { width: '70px', height: '20px' },
      isDone: areDocumentsSigned,
      isLoading:
        initiateDocumentsSigning.isLoading ||
        (signingPending && !areDocumentsSigned)
    }
  ]

  return {
    actions,
    textButton,
    isIntegrationsDone: areDocumentsSigned && isGocardlessComplete,
    finalizeOnboarding: finalizeOnboarding.mutate
  }
}

export default useDirectDebitSectionHook
