import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import authApi from 'config/api/auth'
import { loginSuccess } from 'redux/actions/Auth'
import { jwtDecode } from 'utils/jwtDecode'

// Provide hooks utility for authentication
const useAuth = () => {
  const [isRefreshToken, setIsRefreshToken] = useState(false)
  const dispatch = useDispatch()
  const [isAuthLoading, setIsAuthLoading] = useState(true)

  const getRefreshToken = useQuery(
    'refreshToken',
    () => authApi.refreshToken(),
    {
      onSuccess: (res) => {
        if (!res.token) return

        const decodedToken = jwtDecode(res.token)
        dispatch(
          loginSuccess({
            token: res.token,
            expiresAt: decodedToken.exp,
            impersonating: decodedToken.impersonating
          })
        )
        setIsRefreshToken(Boolean(res.token))
      },
      onSettled: () => {
        setIsAuthLoading(false)
      },
      enabled: false,
      retry: false
    }
  )

  const handleGetRefreshToken = () => {
    return getRefreshToken.refetch()
  }

  return {
    isRefreshToken,
    handleGetRefreshToken,
    isAuthLoading: getRefreshToken.isLoading || isAuthLoading
  }
}

export default useAuth
