import { useStateContext } from 'context/state/provider'
import { Route, Routes } from 'react-router-dom'
import RouteBuilder from 'router/builder'
import { TrailingSlashGuard } from './guards/TrailingSlashGuard'

export const Router = (): JSX.Element => {
  const { state } = useStateContext()
  return (
    <Routes>
      <Route Component={TrailingSlashGuard}>
        {new RouteBuilder(state).getRoutes()}
      </Route>
    </Routes>
  )
}
