import { MultiStepContext } from 'context/multi-step/provider'
import { StateMachine, StateMachineState } from 'models/common'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'

type FlowProps = {
  stateMachine: StateMachine
}

export const Flow = ({ stateMachine }: FlowProps): JSX.Element => {
  const navigate = useNavigate()

  const [state, setState] = useState<StateMachineState>(() =>
    stateMachine.states.find((route) => route.name === window.location.pathname)
  )

  useEffect(() => {
    const newState = stateMachine.states.find(
      (route) => route.name === window.location.pathname
    )
    setState(newState)
  }, [window.location.pathname])

  const next = useCallback(
    (data?: unknown) => {
      if (state.end) {
        window.location.reload()
      } else if (state.guards) {
        for (const g of state.guards) {
          const guardFunction = stateMachine.guards[g.fn]
          if (!guardFunction)
            throw new Error(`Guard function ${g.fn} not found`)

          if (guardFunction(data)) {
            return navigate(g.to)
          } else if (g.else) {
            return navigate(g.else)
          }
        }
      }

      if (state.next) {
        return navigate(state.next)
      }

      throw new Error('[SignUpFlow] State machine incorrectly defined')
    },
    [navigate, state]
  )

  const previous = useCallback(() => {
    if (!!state?.back) navigate(state.back)
  }, [navigate, state])

  const hasPrevious = useMemo(
    () => state.name !== stateMachine.initial,
    [state]
  )
  const hasNext = useMemo(() => !state.end, [state.end])

  return (
    <MultiStepContext.Provider
      value={{ next, previous, hasNext, hasPrevious, steps: state.steps }}
    >
      <Outlet />
    </MultiStepContext.Provider>
  )
}
