export enum StateValue {
  Signup = 'signup',
  SignupRecurring = 'signup-recurring',
  SignupEcommerce = 'signup-ecommerce',
  CompanyInformation = 'company-information',
  NotEligible = 'not-eligible',
  Integrations = 'integrations',
  Submitted = 'submitted',
  Offer = 'offer',
  Dashboard = 'dashboard'
}

export enum AppContext {
  Chassi = 'chassi',
  Merchant = 'merchant'
}

export enum Subdomain {
  Gocardless = 'gocardless',
  Xapix = 'xapix',
  Seko = 'seko',
  Xero = 'xero',
  Base = 'base'
}

export enum Partners {
  Cobiro = 'cobiro'
}
