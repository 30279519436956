import { State } from 'models/state'

export enum StateActionType {
  SetState
}

export type StateAction = {
  type: StateActionType.SetState
  payload: Nullable<State>
}
