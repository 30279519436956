import * as Yup from 'yup'
import { useMultiStep } from 'context/multi-step/provider'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { batch, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useAuthHook from 'components/Auth/hook'
import authApi from 'config/api/auth'
import { updatePersonalInfoValues } from 'redux/actions/Register'
import { getErrorMessage } from 'utils/getErrorMessage'
import { makePasswordStrengthBar } from 'utils/passwordBarUtil'
import personalSchema from './schema'

const usePersonalFormHook = (slug) => {
  const { previous, next } = useMultiStep()
  const [shouldShowError, setShouldShowError] = useState(false)
  const [passwordBar, setPasswordBar] = useState({
    message: '',
    score: 0,
    items: [
      {
        1: 'bg-light-grey-200'
      },
      {
        2: 'bg-light-grey-200'
      },
      {
        3: 'bg-light-grey-200'
      },
      {
        4: 'bg-light-grey-200'
      }
    ]
  })

  const dispatch = useDispatch()
  const dataRegister = useSelector((state) => state.dataRegister)
  const { signupStep, accountValidationData } = useSelector(
    (state) => state.dataAuth
  )

  const user = useSelector((state) => state.dataUser)
  const { handleLoginSuccess } = useAuthHook()
  const { personalInfo } = dataRegister

  const createPersonalInfo = useMutation(authApi.createPersonalInfo, {
    onSuccess: (res) => {
      batch(() => {
        handleLoginSuccess(res.token)
      })
      next()
    },
    onError: (err) => {
      toast.error(getErrorMessage(err))
    }
  })

  const formik = useFormik({
    initialValues: {
      ...personalInfo
    },
    validationSchema: () =>
      Yup.lazy((values) => {
        return personalSchema(values.countryCode)
      }),
    onSubmit: (values) => {
      const normalisedValues = {
        ...values,
        email: values.email.trim(),
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim()
      }
      if (slug) normalisedValues.partner_slug = slug
      dispatch(updatePersonalInfoValues(normalisedValues))
      createPersonalInfo.mutate({ ...personalInfo, ...normalisedValues })
    }
  })

  const handleChange = (field, value) => {
    formik.setFieldValue(field, value)
    if (field === 'password') {
      makePasswordStrengthBar(value, passwordBar.items).then((it) =>
        setPasswordBar(it)
      )
    }
  }

  const onPersonalNext = (e) => {
    e.preventDefault()
    setShouldShowError(true)
    formik.handleSubmit()
  }

  const onClickBack = () => {
    previous()
  }

  return {
    user,
    step: signupStep,
    formik,
    userData: accountValidationData,
    shouldShowError,
    passwordBar,
    loading: createPersonalInfo.isLoading,
    onPersonalNext,
    handleChange,
    onClickBack
  }
}

export default usePersonalFormHook
