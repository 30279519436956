import { AppLayout } from 'layouts'
import { Suspense, lazy } from 'react'
import { Navigate, Outlet, Route } from 'react-router'
import { BaseDomainGuard } from 'router/guards/BaseDomainGuard'
import { NotSaasUserGuard } from 'router/guards/NotSaasUserGuard'
import { ACCOUNTS_BALANCES_ROUTES, ROOT_ROUTES } from 'config/configs/routes'
import AppLayoutSkeleton from 'layouts/AppLayout/AppLayoutSkeleton'

const Benchmarks = lazy(() => import('pages/app/Benchmarks'))
const Documents = lazy(() => import('pages/app/Documents'))
const Sales = lazy(() => import('pages/app/Sales'))
const Settings = lazy(() => import('pages/app/Settings'))
const Connections = lazy(() => import('pages/app/GetConnected'))
const AccountsAndBalancesOverview = lazy(
  () => import('pages/app/AccountsBalances/Overview/Overview')
)
const AccountsAndBalancesInflows = lazy(
  () => import('pages/app/AccountsBalances/Inflows/Inflows')
)
const AccountsAndBalancesOutflows = lazy(
  () => import('pages/app/AccountsBalances/Outflows/Outflows')
)
const AccountsAndBalancesTransactions = lazy(
  () => import('pages/app/AccountsBalances/Transactions/Transactions')
)
const AccountsBalancesCategories = lazy(
  () => import('pages/app/AccountsBalances/Categories')
)
const OverviewCreditLimit = lazy(() => import('pages/app/OverviewCreditLimit'))

type DashboardRoutesProps = {
  initialRoute: string
}

export const DashboardRoutes = ({
  initialRoute
}: DashboardRoutesProps): JSX.Element => {
  return (
    <Route Component={BaseDomainGuard}>
      <Route
        element={
          <Suspense fallback={<AppLayoutSkeleton />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route Component={AppLayout}>
          <Route path={ROOT_ROUTES.DASHBOARD} Component={OverviewCreditLimit} />
          <Route
            element={<NotSaasUserGuard redirect={ROOT_ROUTES.DASHBOARD} />}
          >
            <Route path={ROOT_ROUTES.SALES} Component={Sales} />
            <Route path={ROOT_ROUTES.BENCHMARKS} Component={Benchmarks} />
            <Route
              path={ACCOUNTS_BALANCES_ROUTES.OVERVIEW}
              Component={AccountsAndBalancesOverview}
            />
            <Route
              path={ACCOUNTS_BALANCES_ROUTES.INFLOWS}
              Component={AccountsAndBalancesInflows}
            />
            <Route
              path={ACCOUNTS_BALANCES_ROUTES.OUTFLOWS}
              Component={AccountsAndBalancesOutflows}
            />
            <Route
              path={ACCOUNTS_BALANCES_ROUTES.CATEGORIES}
              Component={AccountsBalancesCategories}
            />
            <Route
              path={ACCOUNTS_BALANCES_ROUTES.TRANSACTIONS}
              Component={AccountsAndBalancesTransactions}
            />
          </Route>
          <Route path={ROOT_ROUTES.CONNECTIONS} Component={Connections} />
          <Route path={ROOT_ROUTES.DOCUMENTS} Component={Documents} />
          <Route path={ROOT_ROUTES.SETTINGS} Component={Settings} />
          <Route path="*" element={<Navigate to={initialRoute} />} />
        </Route>
      </Route>
    </Route>
  )
}
