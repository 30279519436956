import { Suspense, lazy } from 'react'
import { Navigate, Outlet, Route } from 'react-router'
import { SignUpFlow } from 'router/flows/SignUp'
import { PartnerSignupFlow } from 'router/flows/SignUp/PartnerSignupFlow'
import { PartnerIncognitoGuard } from 'router/guards/PartnerIncognitoGuard'
import LoadingScreen from 'components/LoadingScreen'
import { PARTNERS_ROUTES, ROOT_ROUTES } from 'config/configs/routes'
import { SignUp } from 'pages/authorisation'
import CompanyBusinessType from 'pages/authorisation/CompanyBusinessType'

const PartnersApply = lazy(() => import('pages/app/Partners/PartnersApply'))
const PartnersSignUp = lazy(() => import('pages/app/Partners/PartnersSignUp'))
const Login = lazy(() => import('pages/authorisation/Login'))
const ForgotPassword = lazy(() => import('pages/authorisation/ForgotPassword'))
const ResetPassword = lazy(() => import('pages/ResetPassword'))
const PartnersIncognito = lazy(
  () => import('pages/app/Partners/PartnersIncognito')
)
const PartnersMaintenance = lazy(
  () => import('pages/app/Partners/PartnersMaintenance/PartnersMaintenance')
)

type SignupRoutesProps = {
  initialRoute: string
}

export const SignupRoutes = ({
  initialRoute
}: SignupRoutesProps): JSX.Element => {
  return (
    <Route
      element={
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      }
    >
      <Route path={ROOT_ROUTES.LOGIN} Component={Login} />
      <Route Component={SignUpFlow}>
        <Route path={ROOT_ROUTES.SIGNUP} Component={CompanyBusinessType} />
        <Route path={ROOT_ROUTES.SIGNUP_ECOMMERCE} Component={SignUp} />
        <Route path={ROOT_ROUTES.SIGNUP_RECURRING} Component={SignUp} />
      </Route>
      <Route path={ROOT_ROUTES.FORGOT_PASSWORD} Component={ForgotPassword} />
      <Route path={ROOT_ROUTES.RESET_PASSWORD} Component={ResetPassword} />

      <Route Component={PartnerSignupFlow}>
        <Route path={PARTNERS_ROUTES.APPLY} Component={PartnersApply} />
        <Route Component={PartnerIncognitoGuard}>
          <Route path={PARTNERS_ROUTES.SIGNUP} Component={PartnersSignUp} />
          <Route
            path={PARTNERS_ROUTES.INCOGNITO}
            Component={PartnersIncognito}
          />
          <Route
            path={PARTNERS_ROUTES.MAINTENANCE}
            Component={PartnersMaintenance}
          />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to={initialRoute} replace />} />
    </Route>
  )
}
