import { useStateContext } from 'context/state/provider'
import { Flow } from 'router/flows'
import { useSignupFlow } from './useSignupFlow'

export const SignUpFlow = (): JSX.Element => {
  const { state } = useStateContext()
  const { stateMachine } = useSignupFlow(state)

  return <Flow stateMachine={stateMachine} />
}
