import { StateMachine } from 'models/common'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { Flow } from 'router/flows'
import { PARTNERS_ROUTES } from 'config/configs/routes'

const { SIGNUP, APPLY, MAINTENANCE } = PARTNERS_ROUTES

export const PartnerSignupFlow = (): JSX.Element => {
  const { slug: partnerSlug } = useParams()

  const PARTNER_SIGNUP_STATE_MACHINE: StateMachine = useMemo(() => {
    const APPLY_ROUTE = APPLY.replace(':slug', partnerSlug)
    const SIGNUP_ROUTE = SIGNUP.replace(':slug', partnerSlug)

    return {
      initial: APPLY_ROUTE,
      states: [
        {
          name: APPLY_ROUTE,
          guards: [
            {
              fn: 'disabled',
              to: SIGNUP_ROUTE
            },
            {
              fn: 'maintenance',
              to: MAINTENANCE
            }
          ],
          steps: {
            total: 3,
            current: 1
          },
          next: SIGNUP_ROUTE
        },
        {
          name: SIGNUP_ROUTE,
          back: APPLY_ROUTE,
          steps: {
            total: 3,
            current: 2
          },
          end: true
        }
      ],
      guards: {
        disabled: (data?: any) => {
          return !!data?.disabled
        },
        maintenance: (data?: any) => {
          return !!data?.maintenance
        }
      }
    }
  }, [partnerSlug])

  return <Flow stateMachine={PARTNER_SIGNUP_STATE_MACHINE} />
}
