import { OnboardingLayout } from 'layouts'
import { Navigate, Outlet, Route } from 'react-router'
import { SubdomainGuard } from 'router/guards/SubdomainGuard'
import LoadingScreen from 'components/LoadingScreen'
import useOnboardingHook from 'components/Onboarding/hook'
import OnboardingPage from 'pages/app/onboarding/OnboardingPage'

export const OfferRoutes = (): JSX.Element => {
  const { onboardingSteps, loadingOnboardingSteps, ...props } =
    useOnboardingHook()

  if (loadingOnboardingSteps) {
    return <Route path="*" Component={LoadingScreen} />
  }

  return (
    <Route Component={SubdomainGuard}>
      <Route
        element={
          // @ts-ignore
          <OnboardingLayout shouldShowOnboardingSidebar={true} {...props}>
            <Outlet />
          </OnboardingLayout>
        }
      >
        {onboardingSteps.map((step, index) => (
          <Route
            key={index}
            path={step.url}
            element={
              <OnboardingPage
                key={index}
                index={index}
                type={'offer'}
                {...props}
              />
            }
          />
        ))}
        <Route path="*" element={<Navigate to={onboardingSteps[0]?.url} />} />
      </Route>
    </Route>
  )
}
