import { AppContext, StateValue, Subdomain } from 'models/constants/enums'
import { State } from 'models/state'
import { CompanyInformationRoutes } from 'router/contexts/merchant/company-information'
import { DashboardRoutes } from 'router/contexts/merchant/dashboard'
import { IntegrationRoutes } from 'router/contexts/merchant/integrations'
import { NotEligibleRoutes } from 'router/contexts/merchant/not-eligible'
import { OfferRoutes } from 'router/contexts/merchant/offer'
import { SignupRoutes } from 'router/contexts/merchant/signup'
import { GocardlessSignupRoutes } from 'router/contexts/merchant/subdomains/gocardless/signup'
import { SekoSignupRoutes } from 'router/contexts/merchant/subdomains/seko/signup'
import { XapixSignupRoutes } from 'router/contexts/merchant/subdomains/xapix/signup'
import { SubmittedRoutes } from 'router/contexts/merchant/submitted'
import { STATE_TO_ROUTES_MAPPING } from 'config/configs/routes'
import useRouterHook from 'hooks/useRouter'

export default class RouteBuilder {
  state: State

  constructor(_state: State) {
    this.state = _state
  }

  getRoutes(): JSX.Element {
    const { getSubdomain } = useRouterHook()

    if (this.state.context === AppContext.Merchant) {
      switch (getSubdomain()) {
        // Add here the rest of subdomains
        case Subdomain.Gocardless.toString():
          return this.getMerchantGocardlessRoutes()
        case Subdomain.Seko.toString():
          return this.getMerchantSekoRoutes()
        case Subdomain.Xapix.toString():
          return this.getMerchantXapixRoutes()
        default:
          // The default case is the main domain
          return this.getMerchantRoutes()
      }
    }

    // Add here the rest of the app contexts
  }

  private getMerchantRoutes() {
    const initialRoute = STATE_TO_ROUTES_MAPPING[this.state.name]

    switch (this.state.name) {
      case StateValue.Signup:
        return SignupRoutes({
          initialRoute
        })
      case StateValue.CompanyInformation:
        return CompanyInformationRoutes({
          initialRoute
        })
      case StateValue.NotEligible:
        return NotEligibleRoutes({
          initialRoute
        })
      case StateValue.Integrations:
        return IntegrationRoutes()
      case StateValue.Submitted:
        return SubmittedRoutes({
          initialRoute
        })
      case StateValue.Offer:
        return OfferRoutes()
      case StateValue.Dashboard:
        return DashboardRoutes({
          initialRoute
        })
    }
  }

  private getMerchantGocardlessRoutes() {
    const initialRoute = STATE_TO_ROUTES_MAPPING[this.state.name]

    switch (this.state.name) {
      case StateValue.SignupRecurring:
        return GocardlessSignupRoutes({
          initialRoute
        })
      default:
        // The rest of the flows are identical to the base subdomain
        return this.getMerchantRoutes()
    }
  }

  private getMerchantSekoRoutes() {
    const initialRoute = STATE_TO_ROUTES_MAPPING[this.state.name]

    switch (this.state.name) {
      case StateValue.SignupRecurring:
        return SekoSignupRoutes({
          initialRoute
        })
      default:
        // The rest of the flows are identical to the base subdomain
        return this.getMerchantRoutes()
    }
  }

  private getMerchantXapixRoutes() {
    const initialRoute = STATE_TO_ROUTES_MAPPING[this.state.name]

    switch (this.state.name) {
      case StateValue.SignupRecurring:
        return XapixSignupRoutes({
          initialRoute
        })
      default:
        // The rest of the flows are identical to the base subdomain
        return this.getMerchantRoutes()
    }
  }
}
