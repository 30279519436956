import { StateAction, StateActionType } from './actions'
import { StateContextData } from './type'

export const stateReducer = (
  state: StateContextData,
  action: StateAction
): StateContextData => {
  switch (action.type) {
    case StateActionType.SetState:
      return {
        state: action.payload
      }

    default:
      return { ...state }
  }
}
