import { Navigate, Route } from 'react-router'
import { SubdomainGuard } from 'router/guards/SubdomainGuard'
import { REGISTRATION_ROUTES } from 'config/configs/routes'
import NotEligible from 'pages/authorisation/NotEligible'

type NotEligibleRoutesProps = {
  initialRoute: string
}

export const NotEligibleRoutes = ({
  initialRoute
}: NotEligibleRoutesProps): JSX.Element => {
  return (
    <Route Component={SubdomainGuard}>
      <Route path={REGISTRATION_ROUTES.NOT_ELIGIBLE} Component={NotEligible} />
      <Route path="*" element={<Navigate to={initialRoute} replace />} />
    </Route>
  )
}
