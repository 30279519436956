import { isUndefined } from 'lodash'
import { createContext, useContext } from 'react'
import { MultiStepContextData } from './type'

export const MultiStepContext =
  createContext<Optional<MultiStepContextData>>(undefined)

export const useMultiStep = (): MultiStepContextData => {
  const context = useContext(MultiStepContext)
  if (isUndefined(context))
    throw new Error(
      'useMultiStep must be nested under a MultiStepContext instance'
    )
  return context
}
