import { StateMachineSteps } from 'models/common'

class _StepperHelper {
  stepsToArray(steps: StateMachineSteps): boolean[] {
    return Array(steps.total ?? 0)
      .fill(false)
      .fill(true, 0, steps.current)
  }
}

export const StepperHelper = new _StepperHelper()
