import { Navigate, Outlet, useLocation } from 'react-router'

export const TrailingSlashGuard = (): JSX.Element => {
  const location = useLocation()

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return (
      <Navigate
        to={{
          pathname: location.pathname.replace(/\/+$/, ''),
          search: location.search,
          hash: location.hash
        }}
        replace
      />
    )
  }

  return <Outlet />
}
