import { StateValue, Subdomain } from 'models/constants/enums'

export const ROOT_ROUTES = {
  // Auth
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  LOGOUT: '/logout',
  REGISTRATION: '/registration',
  SIGNUP_ECOMMERCE: '/signup/ecommerce',
  SIGNUP_RECURRING: '/signup/recurring',

  // Merchant Onboarding
  ONBOARDING: '/onboarding',

  // Merchant Dashboard
  DASHBOARD: '/',
  SALES: '/sales',
  ACCOUNTS_BALANCES: '/accounts-balances',
  BENCHMARKS: '/benchmarks',
  CONNECTIONS: '/connections',
  DOCUMENTS: '/documents',
  SETTINGS: '/settings',

  //Partners
  PARTNERS: '/partners',

  CONFIRM_ACCOUNT: '/confirm-account',

  // Others
  NOT_FOUND: '/404'
}

const { ONBOARDING, ACCOUNTS_BALANCES, PARTNERS } = ROOT_ROUTES

export const REGISTRATION_ROUTES = {
  COMPANY_BUSINESS_TYPE: `/registration/company-information/business-type`,
  COMPANY_REGISTRATION: `/registration/company-information`,
  NOT_YET_REGISTRATION: `/registration/not-yet`,
  SUCCESS_REGISTRATION: `/registration/success`,
  NOT_ELIGIBLE: `/registration/not-eligible`
}

export const MERCHANT_ONBOARDING_ROUTES = {
  CONNECT_SALES: `${ONBOARDING}/connect-sales`,
  CONNECT_MARKETING: `${ONBOARDING}/connect-marketing`,
  CONNECT_ACCOUNTING: `${ONBOARDING}/connect-accounting`,
  COMPANY_DIRECTORS: `${ONBOARDING}/company-directors`,
  CONNECT_BANK: `${ONBOARDING}/connect-bank`,
  AVAILABLE_FUNDS: `${ONBOARDING}/available-funds`,
  BANK_DETAILS: `${ONBOARDING}/bank-details`,
  DIRECT_DEBIT: `${ONBOARDING}/direct-debit`,
  SUCCESS: `${ONBOARDING}/success`,
  TRANSFER_FUNDS: `${ONBOARDING}/transfer-funds`
}

export const ACCOUNTS_BALANCES_ROUTES = {
  OVERVIEW: `${ACCOUNTS_BALANCES}/overview`,
  INFLOWS: `${ACCOUNTS_BALANCES}/inflows`,
  OUTFLOWS: `${ACCOUNTS_BALANCES}/outflows`,
  TRANSACTIONS: `${ACCOUNTS_BALANCES}/transactions`,
  CATEGORIES: `${ACCOUNTS_BALANCES}/categories`
}

export const PARTNERS_ROUTES = {
  APPLY: `${PARTNERS}/:slug/apply`,
  MAINTENANCE: `${PARTNERS}/maintenance`,
  INCOGNITO: `${PARTNERS}/:slug/incognito`,
  SIGNUP: `${PARTNERS}/:slug/signup`
}

export const SUBDOMAINS_TO_ROUTES_MAPPING: Record<Subdomain, string> = {
  [Subdomain.Gocardless]: ROOT_ROUTES.SIGNUP_RECURRING,
  [Subdomain.Seko]: ROOT_ROUTES.SIGNUP_RECURRING,
  [Subdomain.Xapix]: ROOT_ROUTES.SIGNUP,
  [Subdomain.Xero]: ROOT_ROUTES.SIGNUP,
  [Subdomain.Base]: ROOT_ROUTES.SIGNUP
}

export const STATE_TO_ROUTES_MAPPING: Record<StateValue, string> = {
  signup: ROOT_ROUTES.SIGNUP,
  'signup-recurring': ROOT_ROUTES.SIGNUP_RECURRING,
  'signup-ecommerce': ROOT_ROUTES.SIGNUP_ECOMMERCE,
  'company-information': REGISTRATION_ROUTES.COMPANY_REGISTRATION,
  'not-eligible': REGISTRATION_ROUTES.NOT_ELIGIBLE,
  integrations: MERCHANT_ONBOARDING_ROUTES.CONNECT_SALES,
  submitted: MERCHANT_ONBOARDING_ROUTES.SUCCESS,
  offer: MERCHANT_ONBOARDING_ROUTES.AVAILABLE_FUNDS,
  dashboard: ROOT_ROUTES.DASHBOARD
}
