import { StateMachine } from 'models/common'
import { AppContext, Partners, Subdomain } from 'models/constants/enums'
import { State } from 'models/state'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { PARTNERS_ROUTES, ROOT_ROUTES } from 'config/configs/routes'
import useRouterHook from 'hooks/useRouter'

const { SIGNUP, SIGNUP_ECOMMERCE, SIGNUP_RECURRING } = ROOT_ROUTES

export const useSignupFlow = (state: State) => {
  const { slug } = useParams()
  const { getSubdomain, isSubdomainPermitted } = useRouterHook()
  const subdomain = useMemo(
    () => (isSubdomainPermitted() ? getSubdomain() : Subdomain.Base),
    [isSubdomainPermitted, getSubdomain]
  )
  const partnerSlug = useMemo(
    () => slug || state.partner,
    [slug, state.partner]
  )

  const FLOW_ROUTE_MAPPINGS: Record<
    string,
    Record<string, StateMachine>
  > = useMemo(
    () => ({
      [AppContext.Merchant]: {
        [Subdomain.Base]: {
          initial: SIGNUP,
          states: [
            {
              name: SIGNUP,
              guards: [
                {
                  fn: 'ecommerceCompany',
                  to: SIGNUP_ECOMMERCE,
                  else: SIGNUP_RECURRING
                }
              ],
              steps: {
                total: 3,
                current: 1
              }
            },
            {
              name: SIGNUP_ECOMMERCE,
              back: SIGNUP,
              end: true,
              steps: {
                total: 3,
                current: 2
              }
            },
            {
              name: SIGNUP_RECURRING,
              back: SIGNUP,
              end: true,
              steps: {
                total: 3,
                current: 2
              }
            }
          ],
          guards: {
            ecommerceCompany: (context: any) => {
              const { companyBusinessType } = context
              return companyBusinessType === 'ecommerce'
            }
          }
        },
        [Subdomain.Gocardless]: {
          initial: SIGNUP_RECURRING,
          states: [
            {
              name: SIGNUP_RECURRING,
              end: true,
              steps: {
                total: 3,
                current: 2
              }
            }
          ]
        },
        [Subdomain.Seko]: {
          initial: SIGNUP_RECURRING,
          states: [
            {
              name: SIGNUP_RECURRING,
              end: true,
              steps: {
                total: 3,
                current: 2
              }
            }
          ]
        },
        [Subdomain.Xero]: {
          initial: SIGNUP_ECOMMERCE,
          states: [
            {
              name: SIGNUP_ECOMMERCE,
              end: true,
              steps: {
                total: 3,
                current: 2
              }
            }
          ]
        },
        [Subdomain.Xapix]: {
          initial: SIGNUP,
          states: [
            {
              name: SIGNUP,
              guards: [
                {
                  fn: 'ecommerceCompany',
                  to: SIGNUP_ECOMMERCE,
                  else: SIGNUP_RECURRING
                }
              ],
              steps: {
                total: 3,
                current: 1
              }
            },
            {
              name: SIGNUP_ECOMMERCE,
              back: SIGNUP,
              end: true,
              steps: {
                total: 3,
                current: 2
              }
            },
            {
              name: SIGNUP_RECURRING,
              back: SIGNUP,
              end: true,
              steps: {
                total: 3,
                current: 2
              }
            }
          ],
          guards: {
            ecommerceCompany: (context: any) => {
              const { companyBusinessType } = context
              return companyBusinessType === 'ecommerce'
            }
          }
        },
        [Partners.Cobiro]: {
          initial: PARTNERS_ROUTES.APPLY.replace(':slug', partnerSlug),
          states: [
            {
              name: PARTNERS_ROUTES.APPLY.replace(':slug', partnerSlug),
              guards: [
                {
                  fn: 'disabled',
                  to: SIGNUP
                },
                {
                  fn: 'maintenance',
                  to: PARTNERS_ROUTES.MAINTENANCE
                }
              ],
              steps: {
                total: 3,
                current: 1
              },
              next: PARTNERS_ROUTES.SIGNUP.replace(':slug', partnerSlug)
            },
            {
              name: PARTNERS_ROUTES.SIGNUP.replace(':slug', partnerSlug),
              end: true,
              back: PARTNERS_ROUTES.APPLY.replace(':slug', partnerSlug),
              steps: {
                total: 3,
                current: 2
              }
            }
          ],
          guards: {
            disabled: (data?: any) => {
              return !!data?.disabled
            },
            maintenance: (data?: any) => {
              return !!data?.maintenance
            }
          }
        }
      }
    }),
    [partnerSlug, state]
  )

  const stateMachine: StateMachine = useMemo(() => {
    if (partnerSlug && !!FLOW_ROUTE_MAPPINGS[state.context][partnerSlug])
      return FLOW_ROUTE_MAPPINGS[state.context][partnerSlug]
    return FLOW_ROUTE_MAPPINGS[state.context][subdomain]
  }, [partnerSlug, subdomain])

  return { stateMachine }
}
