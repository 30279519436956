import React from 'react'
import CompanyInformation from 'components/CompanyInformation'
import useCompanyInformation from 'hooks/useCompanyInformation'
import { AuthorisationLayout } from '../../../layouts'
import useSignupHook from '../hooks/useSignupHook'

const CompanyRegistration = ({ name }) => {
  const { companyCurrentStep, setCompanyCurrentStep } = useSignupHook()
  const { handleCreateCompanyInfo } = useCompanyInformation()

  return (
    <AuthorisationLayout screenName={name} hasHeader={true} hasFooter={false}>
      <div className="container mx-auto px-6 md:px-0">
        <div className="flex justify-center mt-6 md:mt-16 3xl:mt-8">
          {/* will render depending on the step in the process. */}
          <div
            className={`relative flex flex-col w-full md:w-7/12 mt-8 xl:mt-0`}
          >
            {/* {<React.Fragment>{signUpSteps[currentStep].component}</React.Fragment>} */}
            <CompanyInformation
              handleCreateCompanyInfo={handleCreateCompanyInfo}
              companyCurrentStep={companyCurrentStep}
              setCompanyCurrentStep={setCompanyCurrentStep}
            />
          </div>
        </div>
      </div>
    </AuthorisationLayout>
  )
}

export default CompanyRegistration
