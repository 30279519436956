import { useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import useRouterHook from 'hooks/useRouter'

export const SubdomainGuard = (): JSX.Element => {
  // @ts-ignore
  const userData = useSelector((state) => state.dataUser)
  const {
    isSubdomainPermitted,
    getBaseDomain,
    getSubdomain,
    redirectSubdomain
  } = useRouterHook()

  // If a regular user tries to connect to a embedded partner URL must be redirect to base URL
  if (!userData?.embeddedPartner && isSubdomainPermitted()) {
    window.location.assign(`${getBaseDomain()}${window.location.pathname}`)
  }

  // If you're a partner but you're not in the correct subdomain
  // you'll be redirected to the correct subdomain
  if (
    userData?.embeddedPartner &&
    getSubdomain() !== userData?.embeddedPartner
  ) {
    window.location.assign(
      `${redirectSubdomain(userData?.embeddedPartner)}${window.location.pathname}`
    )
  }
  return <Outlet />
}
