import {
  GOCARDLESS,
  PHONE_NUMBER,
  SEKO,
  SUBDOMAINS_WITHOUT_BUSINESS_TYPE,
  XAPIX,
  XERO
} from 'config/configs/constant'

const useRouterHook = () => {
  const getSubdomain = () => {
    return window.location.host.split('.')[0]
  }

  const isSubdomainPermitted = () => {
    return [GOCARDLESS, XAPIX, SEKO, XERO].includes(getSubdomain())
  }

  const redirectSubdomain = (subdomain) => {
    return `${process.env.REACT_APP_SUBDOMAIN_PATTERN.replace('{subdomain}', subdomain)}`
  }

  //TODO: Use more clear naming
  const isSubdomainWithoutBusinessType = () => {
    return (
      isSubdomainPermitted() &&
      SUBDOMAINS_WITHOUT_BUSINESS_TYPE.includes(getSubdomain())
    )
  }

  const getBaseDomain = () => process.env.REACT_APP_BASE_URL

  const getPhoneNumber = () => {
    return isSubdomainPermitted() && PHONE_NUMBER?.[getSubdomain()]
      ? PHONE_NUMBER?.[getSubdomain()]
      : PHONE_NUMBER
  }

  const stripRoutePrefix = (route, prefix) => {
    return route.replace(prefix, '')
  }

  return {
    getSubdomain,
    getBaseDomain,
    getPhoneNumber,
    stripRoutePrefix,
    redirectSubdomain,
    isSubdomainPermitted,
    isSubdomainWithoutBusinessType
  }
}

export default useRouterHook
