import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { AuthProvider } from 'context/auth/provider'
import { CompanyInformationProvider } from 'context/company/provider'
import { StateProvider } from 'context/state/provider'
import { SubdomainProvider } from 'context/subdomain/provider'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { Router } from 'router'
import queryClient from 'config/services/queryClient'
import { persistor, store } from 'redux/store'
import { AnalyticsProvider } from './hooks/useAnalytics'
import './index.css'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    enabled_environments: ['staging', 'production'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: function () {
      let trace_rate = Number(process.env.REACT_APP_SENTRY_TRACES_RATE) || 0
      switch (true) {
        case /\/healthcheck/.test(window.location.href):
          trace_rate = 0
          break
        case /\/cable/.test(window.location.href):
          trace_rate = 0.1
          break
        default:
          trace_rate = 0.5
      }
      return trace_rate
    }
  })
}

const channels = require.context('config/channels', true, /_channel\.js$/)
channels.keys().forEach(channels)

const root = ReactDOM.createRoot(document.getElementById('react-app-root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <SubdomainProvider>
            <AuthProvider>
              <CompanyInformationProvider>
                <StateProvider>
                  <AnalyticsProvider>
                    <BrowserRouter>
                      <Router />
                    </BrowserRouter>
                  </AnalyticsProvider>
                </StateProvider>
              </CompanyInformationProvider>
            </AuthProvider>
          </SubdomainProvider>
          <ToastContainer limit={3} transition={Slide} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
