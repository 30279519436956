import { ReactNode, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  GOCARDLESS,
  SAAS_BUSINESS_TYPE,
  SEKO,
  XAPIX
} from 'config/configs/constant'
import useRouterHook from 'hooks/useRouter'
import {
  updateBusinessType,
  updateEmbeddedPartner
} from 'redux/actions/PersistRegister'

type SubdomainProviderProps = {
  children: ReactNode
}

// This is not an actual Context
// But might become in the future
export const SubdomainProvider = ({
  children
}: SubdomainProviderProps): JSX.Element => {
  const dispatch = useDispatch()
  const { getSubdomain, isSubdomainPermitted } = useRouterHook()

  const subdomain = useMemo(() => getSubdomain(), [getSubdomain])
  const subdomainPermitted = useMemo(
    () => isSubdomainPermitted(),
    [isSubdomainPermitted]
  )

  useEffect(() => {
    if (subdomainPermitted) {
      switch (subdomain) {
        case GOCARDLESS:
          dispatch(updateEmbeddedPartner(GOCARDLESS))
          dispatch(updateBusinessType(SAAS_BUSINESS_TYPE))
          return
        case SEKO:
          dispatch(updateEmbeddedPartner(SEKO))
          dispatch(updateBusinessType(SAAS_BUSINESS_TYPE))
          return
        case XAPIX:
          dispatch(updateEmbeddedPartner(XAPIX))
          return
        default:
          dispatch(updateEmbeddedPartner(''))
      }
    }
  }, [subdomain, subdomainPermitted])

  return <>{children}</>
}
