import { AxiosResponse } from 'axios'
import { State } from 'models/state'
import axios from '../../services/axios'

const getState = async (): Promise<State> => {
  const response: AxiosResponse<State> = await axios.get('/api/state')

  return response.data
}

export default {
  getState
}
