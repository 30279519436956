import { StateMachine } from 'models/common'
import { Flow } from 'router/flows'
import { REGISTRATION_ROUTES } from 'config/configs/routes'

const { COMPANY_BUSINESS_TYPE, COMPANY_REGISTRATION } = REGISTRATION_ROUTES

const COMPANY_REGISTRATION_STATE_MACHINE: StateMachine = {
  initial: COMPANY_BUSINESS_TYPE,
  states: [
    {
      name: COMPANY_BUSINESS_TYPE,
      next: COMPANY_REGISTRATION,
      steps: {
        total: 3,
        current: 3
      }
    },
    {
      name: COMPANY_REGISTRATION,
      end: true,
      steps: {
        total: 3,
        current: 3
      }
    }
  ]
}

export const CompanyRegistrationFlow = (): JSX.Element => {
  return <Flow stateMachine={COMPANY_REGISTRATION_STATE_MACHINE} />
}
