import { useSelector } from 'react-redux'
import { Navigate, Route } from 'react-router'
import { CompanyRegistrationFlow } from 'router/flows/CompanyRegistration'
import { SubdomainGuard } from 'router/guards/SubdomainGuard'
import { REGISTRATION_ROUTES } from 'config/configs/routes'
import CompanyBusinessType from 'pages/authorisation/CompanyBusinessType'
import CompanyRegistration from 'pages/authorisation/CompanyRegistration'

type CompanyInformationRoutesProps = {
  initialRoute: string
}

export const CompanyInformationRoutes = ({
  initialRoute
}: CompanyInformationRoutesProps): JSX.Element => {
  const persistedDataRegister = useSelector(
    // @ts-ignore
    (state) => state.persistedDataRegister
  )
  const { companyBusinessType } = persistedDataRegister

  return (
    <Route Component={SubdomainGuard}>
      <Route Component={CompanyRegistrationFlow}>
        <Route
          path={REGISTRATION_ROUTES.COMPANY_REGISTRATION}
          Component={CompanyRegistration}
        />
        <Route
          path={REGISTRATION_ROUTES.COMPANY_BUSINESS_TYPE}
          Component={CompanyBusinessType}
        />
      </Route>
      <Route
        path="*"
        element={
          <Navigate
            to={
              !companyBusinessType
                ? REGISTRATION_ROUTES.COMPANY_BUSINESS_TYPE
                : initialRoute
            }
            replace
          />
        }
      />
    </Route>
  )
}
