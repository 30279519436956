import { Outlet } from 'react-router'
import useRouterHook from 'hooks/useRouter'

export const BaseDomainGuard = (): JSX.Element => {
  const { isSubdomainPermitted, getBaseDomain } = useRouterHook()

  // If you're a partner but you're not in the correct subdomain
  // you'll be redirected to the correct subdomain
  // only if you've not finished submitting the application
  if (isSubdomainPermitted()) {
    window.location.assign(`${getBaseDomain()}${window.location.pathname}`)
  } else {
    return <Outlet />
  }
}
