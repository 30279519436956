import { ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useCompanyInformation from 'hooks/useCompanyInformation'
import useUser from 'hooks/useUser'

type CompanyInformationProviderProps = {
  children: ReactNode
}

export const CompanyInformationProvider = ({
  children
}: CompanyInformationProviderProps): JSX.Element => {
  // @ts-ignore
  const { isLoggedIn } = useSelector((state) => state.dataAuth)
  const { handleGetUser } = useUser()
  const { getCompanyInfo } = useCompanyInformation()

  useEffect(() => {
    if (!isLoggedIn) return

    handleGetUser().then((response) => {
      const { data: user } = response
      if (user.companyInformationId) {
        getCompanyInfo.refetch()
      }
    })
  }, [isLoggedIn])

  return <>{children}</>
}
