import { Suspense, lazy } from 'react'
import { Navigate, Outlet, Route } from 'react-router'
import { SignUpFlow } from 'router/flows/SignUp'
import LoadingScreen from 'components/LoadingScreen'
import { ROOT_ROUTES } from 'config/configs/routes'
import { SignUp } from 'pages/authorisation'
import CompanyBusinessType from 'pages/authorisation/CompanyBusinessType'

const Login = lazy(() => import('pages/authorisation/Login'))
const ForgotPassword = lazy(() => import('pages/authorisation/ForgotPassword'))
const ResetPassword = lazy(() => import('pages/ResetPassword'))

type SignupRoutesProps = {
  initialRoute: string
}

export const XapixSignupRoutes = ({
  initialRoute
}: SignupRoutesProps): JSX.Element => {
  return (
    <Route
      element={
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      }
    >
      <Route path={ROOT_ROUTES.LOGIN} Component={Login} />
      <Route Component={SignUpFlow}>
        <Route path={ROOT_ROUTES.SIGNUP} Component={CompanyBusinessType} />
        <Route path={ROOT_ROUTES.SIGNUP_ECOMMERCE} Component={SignUp} />
        <Route path={ROOT_ROUTES.SIGNUP_RECURRING} Component={SignUp} />
      </Route>
      <Route path={ROOT_ROUTES.FORGOT_PASSWORD} Component={ForgotPassword} />
      <Route path={ROOT_ROUTES.RESET_PASSWORD} Component={ResetPassword} />
      <Route path="*" element={<Navigate to={initialRoute} replace />} />
    </Route>
  )
}
