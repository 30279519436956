import { useMultiStep } from 'context/multi-step/provider'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from 'components/Button'
import CardSelector from 'components/CardSelector'
import useSelectedCompanyFormHook from 'components/CompanyInformation/Step3/SelectedCompanyForm/hook'
import RegistrationStepper from 'components/RegistrationStepper'
import configs from 'config/configs'
import { StepperHelper } from 'utils/helper/stepper'
import { AuthorisationLayout } from '../../../layouts'

const CompanyBusinessType = () => {
  const { next, steps } = useMultiStep()
  const { handleBusinessType } = useSelectedCompanyFormHook({})
  const persistedDataRegister = useSelector(
    (state) => state.persistedDataRegister
  )
  const [stateBusinessType, setStateBusinessType] = useState(
    persistedDataRegister?.companyBusinessType
  )

  const onBusinessSelect = (value) => {
    setStateBusinessType(value)
  }
  const handleNext = () => {
    handleBusinessType('companyBusinessType', stateBusinessType)
    next({ companyBusinessType: stateBusinessType })
  }
  return (
    <AuthorisationLayout
      screenName={'Signup'}
      hasHeader={true}
      hasFooter={false}
      headerBackground={'bg-light-grey-100'}
    >
      <div className="container mx-auto px-6 md:px-0 w-full md:max-w-2xl">
        <div className="flex flex-col justify-center mt-6 md:mt-16 3xl:mt-8">
          <div className="flex justify-center text-xl leading-8 mt-8 xl:mt-0">
            Welcome to Bloom,
          </div>
          <RegistrationStepper steps={StepperHelper.stepsToArray(steps)} />
          <div className="flex justify-center text-xl mb-2 lg:text-40px lg:leading-56px font-black">
            What type of business are you?
          </div>
          <div className="flex flex-col lg:flex-row justify-center gap-4 mt-4">
            <CardSelector
              type={'ecommerce'}
              title={'Ecommerce'}
              subtitle={'(Online Shop, Marketplace)'}
              selected={stateBusinessType}
              onClick={onBusinessSelect}
            />
            <CardSelector
              type={'recurring'}
              title={'Recurring Revenue'}
              subtitle={'(SaaS, Subscription)'}
              selected={stateBusinessType}
              onClick={onBusinessSelect}
            />
          </div>
          {stateBusinessType && (
            <div className="mt-16 flex justify-end items-center">
              <Button
                variant="button-next-pink"
                affixIcon={configs.icons.arrowForward}
                onClick={handleNext}
                children="Next"
              />
            </div>
          )}
        </div>
      </div>
    </AuthorisationLayout>
  )
}

export default CompanyBusinessType
