import { useMultiStep } from 'context/multi-step/provider'
import { Link } from 'react-router-dom'
import PageTitle from 'components/PageTitle'
import { StepperHelper } from 'utils/helper/stepper'
import RegistrationStepper from '../RegistrationStepper'
import ButtonAction from './ButtonAction'
import FormAndTerms from './FormAndTerms'

const PersonalInformation = ({
  slug,
  formik,
  shouldShowError,
  passwordBar,
  onPersonalNext,
  handleChange,
  onClickBack,
  loading
}) => {
  const { steps, hasPrevious } = useMultiStep()

  return (
    <div>
      <div className="justify-between ">
        <div className="flex justify-center text-xl leading-8">
          Welcome to Bloom,
        </div>
        <RegistrationStepper steps={StepperHelper.stepsToArray(steps)} />
        <PageTitle title="Let's create an account" />
        <FormAndTerms
          formik={formik}
          shouldShowError={shouldShowError}
          passwordBar={passwordBar}
          handleChange={handleChange}
        />
      </div>
      <div className="flex">
        {slug && (
          <Link
            to="/login"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center w-16"
          >
            <span className="text-pink-color font-bold border-pink-color border-b hover:opacity-80 transition-opacity">
              Sign in
            </span>
          </Link>
        )}
        <ButtonAction
          loading={loading}
          onClickBack={onClickBack}
          onClickNext={onPersonalNext}
          removeBackButton={!hasPrevious}
        />
      </div>
    </div>
  )
}

export default PersonalInformation
