import { AxiosError } from 'axios'
import { isUndefined } from 'lodash'
import { State } from 'models/state'
import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer
} from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import LoadingScreen from 'components/LoadingScreen'
import StateApi from 'config/api/state'
import { StateAction, StateActionType } from './actions'
import { stateReducer } from './reducer'
import { StateContextData } from './type'

type StateContextWithDispatch = StateContextData & {
  dispatch: Dispatch<StateAction>
}

type StateProviderProps = { children: ReactNode }

const StateContext =
  createContext<Optional<StateContextWithDispatch>>(undefined)

const initialState: StateContextData = {}

export const StateProvider = (props: StateProviderProps): JSX.Element => {
  // @ts-ignore
  const { isLoggedIn } = useSelector((state) => state.dataAuth)
  const [{ state }, dispatch] = useReducer(stateReducer, initialState)
  const { isFetching, refetch } = useQuery<State, AxiosError>(
    ['getState'],
    StateApi.getState,
    {
      staleTime: 10 * 1000, // 10 seconds cache,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (state: State) => {
        dispatch({ type: StateActionType.SetState, payload: state })
        // dispatch({
        //   payload: {
        //     name: StateValue.Signup,
        //     context: AppContext.Merchant,
        //     required: true,
        //     success: StateValue.Dashboard,
        //     failure: null
        //   },
        //   type: StateActionType.SetState
        // })
      }
    }
  )

  useEffect(() => {
    if (!isLoggedIn) {
      refetch()
    }
  }, [isLoggedIn, refetch])

  return isFetching ? (
    <LoadingScreen />
  ) : (
    <StateContext.Provider value={{ state, dispatch }} {...props} />
  )
}

export const useStateContext = (): StateContextWithDispatch => {
  const context = useContext(StateContext)
  if (isUndefined(context))
    throw new Error(
      'useStateContext must be nested under a StateProvider instance'
    )
  return context
}
