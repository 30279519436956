import { Navigate, Route } from 'react-router'
import { SubdomainGuard } from 'router/guards/SubdomainGuard'
import { MERCHANT_ONBOARDING_ROUTES } from 'config/configs/routes'
import OnboardingSuccess from 'pages/app/onboarding/OnboardingSuccess'

type SubmittedRoutesProps = {
  initialRoute: string
}

export const SubmittedRoutes = ({
  initialRoute
}: SubmittedRoutesProps): JSX.Element => {
  return (
    <Route Component={SubdomainGuard}>
      <Route
        path={MERCHANT_ONBOARDING_ROUTES.SUCCESS}
        Component={OnboardingSuccess}
      />
      <Route path="*" element={<Navigate to={initialRoute} replace />} />
    </Route>
  )
}
