import { useMultiStep } from 'context/multi-step/provider'
import { T, always, cond, propEq } from 'ramda'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import companyHouseApi from 'config/api/companyHouse'
import {
  removeSelectedCompany,
  selectCompany,
  setCompletedStep1
} from 'redux/actions/Register'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'
import { StepperHelper } from 'utils/helper/stepper'
import usePrevious from '../../hooks/usePrevious'
import CompanyInformationStep1 from './Step1'
import CompanyInformationStep2 from './Step2'
import CompanyInformationStep3 from './Step3'

const useCompanyInformationHook = ({
  setCompanyCurrentStep,
  companyCurrentStep,
  handleCreateCompanyInfo
}) => {
  const user = useSelector((state) => state.dataUser)
  const [companyName, setCompanyName] = useState('')
  const [companyList, setCompanyList] = useState([])
  const { steps } = useMultiStep()

  const { refetch } = useQuery(
    ['companySearch', companyName],
    () => companyHouseApi.searchCompanies(companyName),
    {
      enabled: false, // Disable auto query on component mount
      onSuccess: (res) => {
        setCompanyList(res)
      }
    }
  )

  const dataRegister = useSelector((state) => state.dataRegister)
  const persistedDataRegister = useSelector(
    (state) => state.persistedDataRegister
  )
  const { companyBusinessType } = persistedDataRegister
  const { selectedCompany, completedStep1 } = dataRegister

  const companySearchResult = companyList.map((company) => {
    const companyTitle = capitalizeFirstLetter(company.title)
    const companyDetail =
      company?.address?.locality ||
      '' + ' ' + company?.address?.region ||
      '' + ' ' + company?.address?.country ||
      ''
    return {
      company,
      companyTitle,
      companyDetail
    }
  })

  const prevCompanyName = usePrevious(companyName)
  const dispatch = useDispatch()

  useEffect(() => {
    if (companyCurrentStep !== 2) {
      if (companyName.length < 1) {
        return setCompanyCurrentStep(0)
      }

      if (prevCompanyName.length === 0) {
        return setCompanyCurrentStep(1)
      }
    }

    const delaySearchCompany = setTimeout(() => {
      refetch()
    }, 300)

    // If none happens, search for companies
    if (prevCompanyName !== companyName) {
      return () => clearTimeout(delaySearchCompany)
    }
  }, [companyName])

  useEffect(() => {
    if (completedStep1) {
      // If done Step 1, move current step to the latest step
      setCompanyCurrentStep(companySteps.length - 1)
      // Because they are now back to step 1, set step 1 to not completed
      dispatch(setCompletedStep1(false))
    }
  }, [])

  const onBackCompanyStep = () => {
    if (companyCurrentStep < 0) {
      return
    }

    setCompanyCurrentStep(companyCurrentStep - 1)
  }

  const onNextCompanyStep = () => {
    setCompanyList([])
    setCompanyCurrentStep(companyCurrentStep + 1)
  }

  const setSelectedCompany = (company) => dispatch(selectCompany(company))

  const onSetSelectedCompanyAndNext = (company) => {
    onNextCompanyStep()
    setSelectedCompany(company)
  }

  const onCantFindCompany = () => {
    dispatch(removeSelectedCompany())
    onNextCompanyStep()
  }

  const onCompanyChange = (value) => {
    setCompanyName(value)
    dispatch(selectCompany({ ...selectedCompany, title: value }))
  }

  const StepTitle = cond([
    [
      propEq('companyBusinessType', 'ecommerce'),
      always(`What's your company name?`)
    ],
    [
      propEq('companyBusinessType', 'recurring'),
      always(`Provide info about your company`)
    ],
    [T, '']
  ])
  const StepSubtitle = cond([
    [
      propEq('companyBusinessType', 'ecommerce'),
      always([
        <div className="min-w-max">Great to meet you </div>,
        <b
          key="ecommerce-firstName"
          className="capitalize truncate ml-2 max-w-fit"
        >
          {user?.firstName}
        </b>,
        '!'
      ])
    ],
    [
      propEq('companyBusinessType', 'recurring'),
      always([
        <div className="min-w-max">Welcome to Bloom,</div>,
        <b
          key="recurring-firstName"
          className="capitalize truncate ml-2 max-w-fit"
        >
          {user?.firstName}
        </b>,
        '!'
      ])
    ],
    [T, ['']]
  ])

  const onSetSelectedCompany = (company) => {
    setCompanyList([])
    setSelectedCompany(company)
  }
  const companySteps = [
    {
      component: (
        <CompanyInformationStep1
          key={1}
          steps={StepperHelper.stepsToArray(steps)}
          StepTitle={StepTitle}
          companyName={companyName}
          StepSubtitle={StepSubtitle}
          setCompanyName={setCompanyName}
          companyBusinessType={companyBusinessType}
        />
      )
    },
    {
      component: (
        <CompanyInformationStep2
          key={2}
          steps={StepperHelper.stepsToArray(steps)}
          StepTitle={StepTitle}
          companyName={companyName}
          StepSubtitle={StepSubtitle}
          setCompanyName={setCompanyName}
          onCantFindCompany={onCantFindCompany}
          companySearchResult={companySearchResult}
          companyBusinessType={companyBusinessType}
          selectCompany={onSetSelectedCompanyAndNext}
        />
      )
    },
    {
      component: (
        <CompanyInformationStep3
          key={3}
          steps={StepperHelper.stepsToArray(steps)}
          StepTitle={StepTitle}
          StepSubtitle={StepSubtitle}
          setCompanyName={setCompanyName}
          selectedCompany={selectedCompany}
          onCompanyChange={onCompanyChange}
          selectCompany={onSetSelectedCompany}
          onBackCompanyStep={onBackCompanyStep}
          setSelectedCompany={setSelectedCompany}
          companySearchResult={companySearchResult}
          companyBusinessType={companyBusinessType}
          handleCreateCompanyInfo={handleCreateCompanyInfo}
        />
      )
    }
  ]

  return {
    companyCurrentStep,
    onBackCompanyStep,
    companySteps,
    setSelectedCompany,
    companyList
  }
}

export default useCompanyInformationHook
