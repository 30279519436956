import { Navigate, Outlet, useParams } from 'react-router'
import { PARTNERS_ROUTES } from 'config/configs/routes'

export const PartnerIncognitoGuard = (): JSX.Element => {
  const { slug } = useParams()
  if (!!window['rudderError']) {
    return <Navigate to={PARTNERS_ROUTES.INCOGNITO.replace(':slug', slug)} />
  }
  return <Outlet />
}
